import React from 'react';
import {
  AboutSection,
  ArticlesSection,
  ContactSection,
  HeroSection,
  InterestsSection,
  Page,
  ProjectsSection,
  Seo,
} from 'gatsby-theme-portfolio-minimal';
import { Section } from 'gatsby-theme-portfolio-minimal/src/components/Section';

export default function IndexPage() {
  return (
    <>
      <Seo title="Viral Ruparel - Vision" />
      <Page useSplashScreenAnimation>
        <Section heading={'Our Vision'}>
          <h3>
            {' '}
            Empowering youth and entrepreneurs to build a brighter future.
          </h3>
          <p>
            We believe every single person has hidden potential. It just takes
            the right mentor to bring out that hidden potential.
          </p>
          <p>
            Offers one-on-one mentorship to software engineers and engineering
            students to help them pave their path to success. We aim to maximize
            their career potential and shape a brighter future.
          </p>
          <p>
            Through our world-class mentorship program, we want to enable
            engineering students to learn and develop new skills or refine
            existing ones to understand and stand apart.
          </p>
          <p>
            Our fellowship program aims to provide proper guidance to
            entrepreneurs to help them start their entrepreneurial journey as
            smoothly as possible.
          </p>
          <p>
            To help students and entrepreneurs build solid networks and grow
            together. We believe that an entrepreneurship journey is lonely and
            that relationships contribute to a significant part of your success.
          </p>
        </Section>
        <ContactSection sectionId="contact" heading="Contact" />
      </Page>
    </>
  );
}
